<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import MtqrAddMain from "@/views/StockManagement/MtqrManage/TabChild/MtqrAddMain";
export default {
  name: "MtqrAdd",
  components: {
    childTab1: MtqrAddMain, 
  },
  data() {
    return {
      activeName: "first",
    }
  },
}
</script>

<style scoped>

</style>