<template>
  <div class="vg_wrapper">
    <div class="vd_search_group">
      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMtrlsNow()">
        <el-row>
          <el-col :md="8">
            <el-form-item label="物料编号:">
              <el-input size="small" v-model.trim="searchForm.mtrl_no" clearable placeholder="请填写物料编号名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="物料名称:">
              <el-input size="small" v-model.trim="searchForm.mtrl_name" clearable placeholder="请填写物料名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="物料类型:">
              <el-select size="small" v-model="searchForm.mtrl_type" placeholder="请选择物料类型" clearable >
                <el-option
                    v-for="item in mtrlType"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="8">
            <el-form-item label="潘通色号：">
              <el-input size="small" v-model="searchForm.mtrl_color" clearable placeholder="请填写潘通色号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="6">
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getMtrlsNow()">查询</el-button>
              <el-button size="small" type="info" icon="el-icon-refresh" @click="getMtrlsNowO()" class="vg_ml_16 vg_mr_8">刷新</el-button>
               <!-- <el-link type="primary" @click="goMtrl" class="vg_cursor"><i class="el-icon-edit-outline"></i>物料信息表管理</el-link> -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-row class="vd_mar15">
      <div><i class="el-icon-info vg_mr_8"></i>{{title}}</div>
    </el-row>
    <el-row class="vd_mrt">
      <el-col :md="24">
        <el-table ref="multiTable" :data="tableData" @row-dblclick="confirmIn"  border v-loading="loading" class="vg_cursor">
          <!-- <el-table-column type="selection" width="48"  align="center" :reserve-selection="true"/> -->
          <el-table-column label="物料编号" prop="mtrl_no"/>
          <el-table-column label="物料图片" align="center">
            <template slot-scope="scope">
              <el-image 
                v-if="scope.row.imge_url"
                class="vd_elimg"
                :src='formatPic(scope,"s")'
                fit="fill">
              </el-image>
              <!-- :src="helper.picUrl(scope.row.imge_url,'m')"   -->
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="物料名称" prop="mtrl_name" />
          <el-table-column label="规格" prop="mtrl_spec"  show-overflow-tooltip >
            <template slot-scope="scope">
              <div v-if="scope.row.mtrl_spec">
                {{ scope.row.mtrl_spec}}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <el-table-column label="潘通色号" prop="mtrl_color"  show-overflow-tooltip >
            <template slot-scope="scope">
              <div v-if="scope.row.mtrl_color">
                <div class="vd_dis">
                  <div :class="{'vd_div':scope.row.colr_html}" :style="{'background-color': scope.row.colr_html}"></div>
                  <span>{{ scope.row.mtrl_color}}</span> 
                </div>
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <el-table-column label="门幅" prop="mtrl_width" >
            <template slot-scope="scope">
              <div v-if="scope.row.mtrl_width">
                {{ scope.row.mtrl_width | formatMtrlWidth}}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <el-table-column label="物料毛高" prop="mtrl_thick">
            <template slot-scope="scope">
              <span v-if="scope.row.mtrl_thick">
                {{ scope.row.mtrl_thick | formaUnitH}}
              </span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="类型" prop="mtrl_type"  :formatter="formatLeavType" />
          <el-table-column label="单位" prop="mtrl_unit" >
            <template slot-scope="scope">
              <div v-if="scope.row.mtrl_unit">
                {{ scope.row.mtrl_unit}}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination ref="pubPagination" :totalPage = 'totalPage' @changePageSearch = "changePageSearch"></pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {mtrlAPI} from "@api/modules/mtrl"
import pubPagination from "@/components/common/pubPagination";
import helper from "@assets/js/helper.js"

export default {
  name: "SmplEditBom",
  components:{
    pubPagination,
  },
  data() {
    return {
      tableData:[],
      searchForm:{
        mtrl_no:null,
        mtrl_name:null,
        mtrl_type:null,
        mtrl_color:null
      },
      totalPage:0,
      btn:{},
      loading: true,
      currentPage:1,
      mtrlType:[
        {id:0,label:'原面料'},
        {id:1,label:'辅料'},
        {id:2,label:'包材'},
      ],
      title:'提示：相同数据仅限导入一条',
      imgUpdatatime:parseInt(Math.random()*100000000)
    }
  },
  created() {
    this.initData()
  },
  filters:{
    formatMtrlWidth(row){
      return helper.haveFour(row)+'米'
    },
    formatMtrlThick(row){
      return helper.reservedDigits(row)
    },
    formaUnitH(row){
      return helper.reservedDigits(row)+'毫米'
    },
  },
  methods: {
    initData(){
      this.getMtrlsList()
    },
    // 获取物料信息
    getMtrlsList(){
      const searchBody = this.searchForm;
      searchBody.page_no=this.currentPage;
      searchBody.status=2;
      get(mtrlAPI.getMtrls,searchBody)
      .then(res=>{
        if(res.data.code === 0){
          this.tableData = res.data.data.list;
          this.totalPage = res.data.data.total;
          this.btn = res.data.data.btn;
          setTimeout(() => {
            this.loading = false
          }, 300);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
          setTimeout(() => {
            this.loading = false
          }, 300);
        }
      }).catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
        setTimeout(() => {
            this.loading = false
          }, 300);
      })

    },
    // 清空
    clear(){
      this.$refs.multiTable.clearSelection();
    },
    // 查询方法
    getMtrlsNow(){
      this.$refs.pubPagination.currentPage = 1
      this.loading = true
      this.currentPage = 1
      this.getMtrlsList()
    },
    // 刷新
    getMtrlsNowO(){
      this.$refs.pubPagination.currentPage = 1
      this.loading = true
      this.currentPage = 1
      this.searchForm ={
        mtrl_no:null,
        mtrl_name:null,
        mtrl_type:null,
        mtrl_color:null
      },
      this.getMtrlsList()
    },
    // 跳转物料信息表
    goMtrl(){
      this.$emit('falseMtrl')
      this.jump(`/mtrl_list?perm_id=${this.helper.retPermId('mtrl')}`)
    },
    // 分页查询
    changePageSearch(val){
      this.currentPage = val
      this.getMtrlsList()
    },
    // 物料类型
    formatLeavType(row){
      if(row.mtrl_type === 0){
        return '原面料'
      } else if(row.mtrl_type === 1){
        return '辅料'
      } else if(row.mtrl_type === 2){
        return '包材'
      }
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time)
    },
    // 取消选择
    cancel(){
      this.$emit("mtrlChanel",false)
    },
    // 确认选择
    confirmIn(val){
      this.$emit("mtrlConfirm",val)
    },
     // 图片类型
    formatPic(scope, type) {
      let updateTime = this.imgUpdatatime
      return this.helper.picUrl(
        scope.row.imge_url,
        type,
        updateTime,
        scope.row.imge_source
      );
    },
  }
}
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_mrt{
  margin-top: 20px;
}
.vd_mar15{
  margin: 15px 0;
}
.vd_div{
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}
.vd_dis{
  display: flex;
}
</style>
